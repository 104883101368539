import p5 from "p5";
import "./style.scss";
import sakura from "./sakura.png";

const W = 400;
let H = 0;
const F = 1.0;
const fps = 30;
const N = 1000;
const Zmin = 30;
const Zmax = 300;
const Dmax = 6;
const Dmin = 3;
const K = 1;
const Umax = 3;
const Alpha = 10;
const Margin = 200; // 上下端から何ピクセルで透明になり始めるか
const [R, G, B] = [230, 250, 255];
const Om = 0.05;

class Snow {
  x: number; // cordination
  y: number;
  z: number;
  d: number; // diameter
  u: number; // velocity
  v: number;
  th: number;
  om: number;

  constructor() {
    this.reset();
  }

  reset() {
    this.z = Math.random() * (Zmax - Zmin) + Zmin;
    this.y = -F * this.z;
    this.x = (Math.random() * 2 - 1) * F * this.z;
    this.d = Math.random() * (Dmax - Dmin) + Dmin;
    this.u = (Math.random() * 2 - 1) * Umax;
    this.v = this.d * K;
    this.th = Math.random() * Math.PI * 2;
    this.om = Math.random() * Om;
  }

  nextStep() {
    this.x += this.u / fps;
    this.y += this.v / fps;
    if (this.isOut()) this.x = -this.x;
    if (this.isDropped()) this.reset();
    this.th += this.om;
  }

  isOut() {
    return ((Math.abs(this.x) - this.d) * (H / W)) / this.z > F;
  }

  isDropped() {
    return (this.y + this.d) / this.z > F;
  }
}

const sketch = (p: p5) => {
  let snows: Snow[] = [];
  let img: p5.Image;

  const drawSnow = (s: Snow) => {
    const x = ((s.x / (s.z * F)) * H) / 2 + W / 2;
    const y = ((s.y / (s.z * F)) * H) / 2 + H / 2;
    const d = ((s.d / (s.z * F)) * H) / 2;

    const m = Math.max(H / 2 - Math.abs(y - H / 2), 0); // 上下端からの距離

    const alpha = 255 - 255 * (Alpha / (s.z - Zmin) + (Margin - m) / Margin);
    p.push();
    p.translate(x, y);
    p.rotate(s.th);
    p.image(img, 0, 0, d, d);
    p.pop();

    // p.image(img, x, y, d, d);
  };

  p.preload = () => {
    img = p.loadImage(sakura);
  };

  p.setup = () => {
    H = p.windowHeight - 20;
    p.createCanvas(W, H);
    [...Array(N)].forEach(() => {
      snows.push(new Snow());
    });
    p.imageMode(p.CENTER);
  };

  p.draw = () => {
    p.background(0);

    snows.forEach((snow) => {
      drawSnow(snow);
    });

    snows.forEach((snow) => {
      snow.nextStep();
    });
  };
};

new p5(sketch);
